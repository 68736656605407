<template>
    <transition name="fade" v-if="chartData.length > 0">
        <v-card>
            <v-card-title>
                    <span class="subtitle-1"><v-icon left>mdi-chart-bar</v-icon> Cashier revenue</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="card1 = !card1">
                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                    </v-btn>
            </v-card-title>
            <v-divider v-show="card1"></v-divider>
            <v-card-text v-show="card1">
                <div id="chart">
                <apexchart type="bar" height="1500" :options="chartOptions" :series="series"></apexchart>
            </div>
            </v-card-text>
        </v-card>
    </transition>
</template>

<script>
import { EventBus } from '@/main.js'
import VueApexCharts from 'vue-apexcharts'

    export default {
        name: 'revenue-cashier',

        components: {
            apexchart: VueApexCharts,
        },

        data: () => ({
            card1: false,
            chartData: [],

            series: [{
            data: [/*400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380*/]
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [/*'South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
                'United States', 'China', 'Germany','South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
                'United States', 'China', 'Germany','South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
                'United States', 'China', 'Germany'*/
              ],
            },

            tooltip: {
                fixed: {
                    enabled: false,
                },
                x: {
                    show: true,
                },
                y: {
                    title: {
                    formatter: function (seriesName) {
                        return 'Revenue (€):';
                    },
                    },
                },
                marker: {
                    show: false,
                },
            }
          }
        }),

        computed: {
            getChartData() {
                return this.chartData
            }
        },

        methods: {

        },

        created() {
            
        },

        mounted() {
            let vm = this;
            EventBus.$on('widgets-data', (data) => {
            //window.console.log("x");
            //window.console.log(data);

                vm.series = [{
                    data: []
                }]

                vm.chartOptions.xaxis.categories = new Array();

                let seriesData = []
                let c = 0;
                data.cashier_revenue.forEach(item => {
                    if(c < 200) {
                        vm.chartOptions.xaxis.categories.push(item.name)
                        seriesData.push(item.value)
                    }

                    c++
                })

                //window.console.log(seriesData)
                //window.console.log(vm.chartOptions.xaxis.categories)

                vm.series = [{
                    data: seriesData
                }]

                vm.chartData = seriesData;
            })
        },

        beforeDestroy() {
            EventBus.$off('widgets-data'); 
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
    }

    .fade-enter-active {
        transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }
</style>

